import { useEffect } from 'react';

import { Routes, Route } from 'react-router-dom';
import { GrowthBookProvider, GrowthBook } from "@growthbook/growthbook-react";

import amplitude from 'amplitude-js';

import ReactPixel from 'react-facebook-pixel';

import TiktokPixel from 'tiktok-pixel';

import AgeGroup from "./pages/ageGroup/AgeGroup";
import Payment from './pages/payment/Payment';
import CreateAccount from "./pages/createAccount/CreateAccount";
import Failure from './pages/paymentFailure/PaymentFailure';
import Paywall from './pages/paywall/Paywall';
import Email from './pages/email/Email';
import { RecurlyPaymentForm } from './pages/payment/RecurlyPaymentForm';
import StepsComponent from "./pages/stepper/StepsComponent";
import Intro from "./pages/intro/Intro";

import React from 'react';
import { RecurlyProvider, Elements } from '@recurly/react-recurly';
import { v4 as uuidv4 } from 'uuid';

import testRatesData from "./core/static/paywallTestRates.json";
import strings from "./assets/localization/strings";
import Facebook from "./hooks/FacebookPixel.js";

const growthbook = new GrowthBook({
  apiHost: "https://cdn.growthbook.io",
  clientKey: "sdk-tbi5NRjBnDAf7aK",
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.key
    });
    }
});

const campaignsList = ['a','b','bf','c','h','ny', 'sh', 'shkiki', 'shjasmine', 'shret022470', 'sh032450', 'sh032470', 'chl', 'chl_ret70', 'ret70', 'chltr', 'chl_Akilah', 'rel06', 'chln', 'cfo', 'cf', 'chln_ceo', 'chln_Avery', 'cf_Akilah', 'chln_Alessa', 'chln_halloween', 'cf_halloween']
const themesList = ['purple', 'pink', 'halloween']
const purpleThemeCampaigns = ["shret022470", 'cf']

const analyzeParam = (itemValue, itemName) => {
    const sessionValue = sessionStorage.getItem(itemName)
    const localValue = localStorage.getItem(itemName)
    if(!isParamEmpty(localValue)) {
        sessionStorage.setItem(itemName, localValue)
        return localValue
    }
    if(!isParamEmpty(sessionValue)) {
        return sessionValue
    }
    return null
}

const isParamEmpty = (value) => {
    return value === null || value === "null" || value === "" || value === undefined
}

function App() {
    const campaign = analyzeParam(localStorage.getItem('campaign'), 'campaign')
    const language = analyzeParam(localStorage.getItem('lang'), 'lang')
    let checkoutVrs = analyzeParam(localStorage.getItem('checkout'), 'checkout')
    let planProgressVrs = analyzeParam(localStorage.getItem('plan_progress'), 'plan_progress')
    let country = analyzeParam(localStorage.getItem('country'), 'country')
    let partner = analyzeParam(localStorage.getItem('partner'), 'partner')
    let nutrition = analyzeParam(localStorage.getItem('nutrition'), 'nutrition')
    let theme = analyzeParam(localStorage.getItem('theme'), 'theme')
    let specialOffer = localStorage.getItem('nutrition')

    country = isParamEmpty(country) ? "eu" : country
    partner = isParamEmpty(partner) ? null : partner
    nutrition = isParamEmpty(nutrition) ? null : nutrition
    specialOffer = isParamEmpty(specialOffer) ? 2 : specialOffer
    checkoutVrs = isParamEmpty(checkoutVrs) ? 1 : checkoutVrs
    planProgressVrs = isParamEmpty(planProgressVrs) ? 1 : planProgressVrs

    strings.setLanguage(isParamEmpty(language) ? "en" : language)
    console.log("checkoutVrs: " + checkoutVrs)
    console.log("planProgressVrs: " + planProgressVrs)

    let themeFileName = themesList.indexOf(theme) > -1 ? theme : 'default'
    if(purpleThemeCampaigns.indexOf(campaign) > -1 && themeFileName === 'default') {
        themeFileName = 'purple'
    }
    let themeData = require(`./core/themes/${themeFileName}.json`);

    const settingsFile = campaignsList.indexOf(campaign) > -1 ? campaign : 'default'
    let data = require(`./core/campaigns/${settingsFile}.json`);
    if(data.settings.paywall.trialing && data.settings.paywall.trialing === true){
        localStorage.setItem('discountStatus', "trial");
    }
    data = {
        ...data,
        themeData: themeData,
        country: country,
        partner: partner,
        nutrition: nutrition,
        specialOffer: specialOffer
    }

    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);

    let l = window.location;
    let urlParts = l.host.split(".")
    let domain = l.host
    localStorage.setItem('domain', domain);
    let subdomain = urlParts.length >= 2 ? l.host.split(".")[0] : ''

    const liveMode = subdomain !== 'test' && subdomain !== 'test2'
    data = { ...data, country: country, partner: partner, liveMode: liveMode, language: language, domain: domain, planProgress: planProgressVrs }
    if(!liveMode){
        let products = {}
        if(campaign.includes('ret')){
        products[country] = testRatesData.retarget
        } else{
         products[country] = testRatesData.default
        }

        products[`${country}_a`] = testRatesData.a
        products[`${country}_b`] = testRatesData.b
        products[`${country}_trial`] = testRatesData.trial
        products[`${country}_discount`] = testRatesData.discount
        products[`${country}_n`] = testRatesData.default_n
        products[`${country}_trial_n`] = testRatesData.trial_n
        products[`${country}_discount_n`] = testRatesData.discount_n
        data = { ...data, products: products }
    }
    //const queryParams = queryString.parse(localStorage.getItem('search'));
    const userProperties = {
        utmSource: localStorage.getItem('utm_source'),
        utmMedium: localStorage.getItem('utm_medium'),
        utmCampaign: localStorage.getItem('utm_campaign'),
        utmContent: localStorage.getItem('utm_content')
    };

    const amplitudeOnInit = (instance) => {

    };

    amplitude.getInstance().onInit(amplitudeOnInit);
    const initializeAmplitude = amplitude.getInstance().init('0f240f05cd42b2b601303fa61d325c37');
    if(localStorage.getItem('user_id') !== null && localStorage.getItem('user_id') !== undefined ){
        amplitude.getInstance().setUserId(localStorage.getItem('user_id'));
    } else{
        const v4Id = uuidv4();
        localStorage.setItem('user_id', v4Id);
        amplitude.getInstance().setUserId(v4Id);
    }
    amplitude.getInstance().setUserProperties(userProperties);

    useEffect(() => {
        console.log("11 / 11");
        localStorage.removeItem('popup');
        localStorage.removeItem('gift');
        localStorage.removeItem('discountStatus');
        localStorage.removeItem('with_nutrition');
        localStorage.removeItem('type_of_diet');
        const id = Date.now();
        document.cookie = `id=${id}`;
        ReactPixel.init(domain.includes('net') ? '1085241235883188' : '239948538703054', {external_id: id});
        TiktokPixel.init('CP2RST3C77UF83EV1CUG');

        //amplitude.getInstance().onInit(amplitudeOnInit);
        //amplitude.getInstance().setUserProperties(userProperties);
        fetch('https://al9yh4rktb.execute-api.us-east-1.amazonaws.com/dev/live_classes_offer', {
                          method: "POST",
                          headers: {
                                 'Accept': 'application/json',
                                 'Content-Type': 'application/json'
                                  }
                          },)
                      .then(response => response.json())
                      .then(data => localStorage.setItem('liveClasses', JSON.stringify(data)))
                      .catch(error => console.log(error))

        growthbook.loadFeatures();
        growthbook.setAttributes({
            "id": new Date(),
            "loggedIn": true,
            "employee": true
        });
    }, []);

    let pathValue;
    if(l.hash !== null && l.hash !== undefined && l.hash !== ""){
        let array = l.pathname.split('/');
        pathValue = array.slice(1, array.length + 1)[0];
    }
    amplitude.getInstance().setUserProperties({"subdomain": subdomain});
    amplitude.getInstance().setUserProperties({"campaign": campaign});

    return pathValue === "paywall" ? (
        <div className="App">

           <Routes>
             <Route path='/' element={
                 <GrowthBookProvider growthbook={growthbook}>
                     <Paywall data={data} />
                 </GrowthBookProvider>}
             />
             <Route path="steps" element={<StepsComponent data={data} />} />
             <Route path="paywall" element={
               <GrowthBookProvider growthbook={growthbook}>
                 <Paywall data={data} />
               </GrowthBookProvider>}
             />
             <Route path="payment" element={<Payment data={data} partner={partner} domain={domain} checkout={checkoutVrs}/>} />
//             <Route path="payment" element={<RecurlyProvider publicKey="ewr1-I0ZlpZw9YDHMXDZF0ykIVz">
//                                                  <Elements>
//                                                    <RecurlyPaymentForm />
//                                                  </Elements>
//                                                </RecurlyProvider>} />
             <Route path="registration" element={<CreateAccount />} />
             <Route path="failure" element={<Failure />} />
           </Routes>
         </div>
    ) : pathValue === "payment" ? (
        <div className="App">
          <Routes>
            <Route path='/' element={<Payment data={data} partner={partner} domain={domain} checkout={checkoutVrs}/>}
            />
            <Route path="steps" element={<StepsComponent data={data} />} />
            <Route path="email" element={<Email />} />
            <Route path="paywall" element={
              <GrowthBookProvider growthbook={growthbook}>
                <Paywall data={data} />
              </GrowthBookProvider>}
            />
            <Route path="payment" element={<Payment data={data} partner={partner} domain={domain} checkout={checkoutVrs}/>} />
            <Route path="registration" element={<CreateAccount />} />
            <Route path="failure" element={<Failure />} />
          </Routes>
        </div>
    ) : (
        <div className="App">
            <Facebook/>
            <Routes>
              <Route path='/' element={
                <GrowthBookProvider growthbook={growthbook}>
                    { data.withIntro ? (<Intro data={data} />) : (<AgeGroup data={data} />) }
                </GrowthBookProvider>}
              />
              <Route path="steps" element={<StepsComponent data={data}/>} />
              <Route path="paywall" element={
                <GrowthBookProvider growthbook={growthbook}>
                  <Paywall data={data} />
                </GrowthBookProvider>}
              />
              <Route path="payment" element={<Payment data={data} partner={partner} domain={domain} checkout={checkoutVrs}/>} />
              <Route path="registration" element={<CreateAccount />} />
              <Route path="failure" element={<Failure />} />
            </Routes>
          </div>
        );
}

export default App;
