import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';

import './AboutInput.scss';
import { styled } from "@mui/material/styles";
import styles from "./AboutInput.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const CssTextField = styled(TextField)({
    '.MuiInput-root': {
        height: '40px',
        width: '80px',
        '& input': {
            caretColor: '#E25869',
            textAlign: 'center',
            fontSize: '26px',
            padding: '0'
        }
    },
    '& label.Mui-focused': {
        color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {

        '& fieldset': {
            borderColor: '#E0E3E7',
        },
        '&:hover fieldset': {
            borderColor: '#B2BAC2',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6F7E8C',
        },
    },
});

const AboutInput = props => {
    const { checkResult, inputType, min, max, currentValue, autoFocus, onFocus, onBlur } = props;
    const [inputValue, setInputValue] = useState(currentValue);

    useEffect(() => {
        setInputValue(currentValue)
    }, [currentValue])

    const numericValidations = (value) => {
        if(!value)
            return { errorMessage: '', disableButton: true }
        if(value.match("^[0-9]*$") === null)
            return { errorMessage: strings.about.errors.numeric, disableButton: true }
        if(value && min && value < min) {
            return { errorMessage: strings.about.errors.errorGreater + `${min}`, disableButton: true }
        }
        if(value && max && value > max) {
            return { errorMessage:  strings.about.errors.errorLess + `${max}`, disableButton: true }
        }
        return { errorMessage: '', disableButton: false }
    }

    const changedInfo = (e) => {
        setInputValue(e.target.value)
        checkResult({
            value: e.target.value,
            inputType,
            ...numericValidations(e.target.value)
        });
    }

    return (
        <div className={`${styles.mainContainer} flex-row`}>
            <CssTextField
                sx={{
                    '&:hover': { bgcolor: 'transparent' },
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                autoFocus={autoFocus}
                defaultValue={currentValue}
                value={inputValue}
                variant="standard"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                color="warning"
                onChange={e => changedInfo(e)}
            />
            <span className={styles.inputType}>{inputType}</span>
        </div>
    )
}   

export default AboutInput;