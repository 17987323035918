import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import data from "../../../core/static/about/gender.json";
import useJsonParse from "../../../hooks/useJsonParse";
import CustomCheckbox from "../../UI/checkbox/Checkbox";
import ImageCard from "../../UI/imageCard/ImageCard";

import styles from "./Gender.module.scss";

const Gender = props => {
    const { nextStep } = props;
    const parsedData = useJsonParse(data);
    const [noneCard, setNoneCard] = useState({id: 2, title: 'non-binary', checked: false});

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_gender_appeared');
    }, [])

    const checkCard = card => {
        setNoneCard(card);
        amplitude.getInstance().logEvent('button_onboarding_gender_tapped', {answer: card.title});
        const answer = new Map();
        answer.set("key", "gender");
        answer.set("value", card.title);
        nextStep(answer);
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1>What is your gender?</h1>
            <span className={`${styles.marginTitle} componentSubtitle`}>This will help us calculate your basal metabolic rate and adapt your workout plan.</span>
            <div className={`${styles.imageContainer} flex-between`}>
                { parsedData?.map(card => <ImageCard key={card.id} data={card} selectCard={checkCard} /> ) }
            </div>
            <div className={`${styles.checkboxContainer} flex-row`}>
                <CustomCheckbox data={noneCard} checkCard={checkCard}/>
                <span>Non-Binary</span>
            </div>
        </div>
    )
}   

export default Gender;