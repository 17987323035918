// import { useRef } from "react";
import styles from "./Switcher.module.scss";

const SwitcherComponent = ({ options, value, onChange }) => {
  // const highlight = useRef();

  return (
    <div className={`${styles.switchContainer} flex-between`}>
      {/* <div className={styles.switchButton} ref={highlight}></div> */}
      {options.map(n => (
        <button
          key={n.value}
          className={`${n.value === value ? styles.isActive : ''}`}
          onClick={() => onChange(n.value)}
        >{n.text}</button>
      ))}
    </div>
  )
}

export default SwitcherComponent;